.layout {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
}

.appbar-info {
  display: flex;
  align-items: center;
}

.appbar-info-logo {
  width: 117.82px;
  margin-right: 17.56px;
}

.header {
  width: 100%;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.appbar {
  max-width: 1600px;
  padding: 0 31px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.appbar-info-slogen {
  font-size: 22px;
  color: #1a1a1a;
}

.appbar-submit {
  width: 185px;
  height: 42px;
  border-radius: 4px;
  background-color: #6348ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.component {
  max-width: 1600px;
  padding: 0 31px;
  padding-top: 100px;
  margin: 0 auto;

  @media screen and (max-width: 700px) {
    padding: 0 20px;
    padding-top: 83px;
  }
}

.appbar-mobile {
  display: none;
  width: 100%;
  height: 83px;
  padding: 0 20px;
  padding-top: 22px;

  @media screen and (max-width: 700px) {
    display: block;
  }
}

.appbar-mobile-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.02px;
}

.appbar-mobile-main-logo {
  width: 71.75px;
  display: block;
}

.appbar-mobile-main-submit {
  width: 113px;
  height: 27px;
  border-radius: 4px;
  background-color: #6348ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.appbar-mobile-slogen {
  font-size: 12px;
  line-height: 16px;
  color: #1a1a1a;
  margin-bottom: 12px;
}

.appbar-mobile-pwa {
  width: 100%;
  cursor: pointer;
}

.appbar-mobile-pwa img {
  width: 100%;
  display: block;
}

.submit-modal-container {
  width: 869px;
  height: 192px;
  border-radius: 30px;
  background-color: white;
  padding: 24px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);

  @media screen and (max-width: 700px) {
    width: 88%;
    height: auto;
    border-radius: 12px;
  }
}

.submit-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.submit-modal-title-text {
  font-size: 30px;
  line-height: 41px;
  color: black;
  font-weight: bold;
  font-style: italic;

  @media screen and (max-width: 700px) {
    font-size: 20px;
    line-height: 27px;
  }
}

.submit-modal-title-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 16px;
    height: 16px;
  }
}

.submit-modal-title-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.submit-modal-input {
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.submit-modal-input-wrap {
  flex: 1;
  height: 56px;
  background-color: #f6f6f6;
  border-radius: 4px;

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 42px;
    margin-bottom: 20px;
  }
}

.submit-modal-input-submit {
  width: 142px;
  height: 56px;
  margin-left: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 33px;
  color: white;
  font-weight: bolder;
  font-style: italic;
  background-color: #6348ff;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    height: 44px;
    font-size: 20px;
  }
}

.submit-modal-input-submit.disable {
  opacity: 0.63;
}

#url-input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline-style: none;
  padding: 0 22px;
  font-size: 21px;
  line-height: 29px;
  color: #666666;

  @media screen and (max-width: 700px) {
    font-size: 14px;
    line-height: 19px;
  }
}

::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666666;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666666;
}
input::-webkit-input-placeholder {
  color: #666666;
}

.loading-icon {
  -webkit-animation: spin 1s linear 1s 5 alternate;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
