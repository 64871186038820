.event-card {
  width: 100%;
  height: 319px;
  background-color: white;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.event-card-cover {
  width: 100%;
  height: 188px;
}

.event-card-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card-info {
  flex: 1;
  padding: 12px 18px;
}

.event-card-info-title {
  width: 100%;
  height: 55px;
  font-size: 20px;
  line-height: 27px;
  color: black;
  font-weight: bolder;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 4px;
}

.event-card-info-time {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.event-card-info-time img {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 10px;
}

.event-card-info-time-text {
  font-size: 14px;
  line-height: 19px;
  color: black;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
}

.event-card-info-place {
  display: flex;
  align-items: center;
}

.event-card-info-place img {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 10px;
}

.event-card-info-place-text {
  font-size: 14px;
  line-height: 19px;
  color: black;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
}
