.home {
  width: 100%;
  overflow: hidden;
  padding-top: 32px;

  @media screen and (max-width: 700px) {
    padding-top: 20px;
  }
}

.home-tab-bar {
  display: flex;
  margin-bottom: 23px;
}

.home-tab-bar-item {
  height: 49px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 60px;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    height: 35px;
    margin-right: 42px;
  }
}

.home-tab-bar-item span {
  font-size: 28px;
  line-height: 38px;
  color: #1a1a1a;
  font-weight: bold;
  font-style: italic;

  @media screen and (max-width: 700px) {
    font-size: 19px;
    line-height: 27px;
  }
}

.home-tab-bar-item-dot {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: transparent;

  @media screen and (max-width: 700px) {
    width: 7.7px;
    height: 7.7px;
  }
}

.home-tab-bar-item.active span {
  color: #6348ff;
}

.home-tab-bar-item.active .home-tab-bar-item-dot {
  background-color: #6348ff;
}

.home-title {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.home-title-text {
  font-size: 28px;
  line-height: 38px;
  color: #1a1a1a;
  font-weight: bold;
  font-style: italic;
  margin-right: 18px;
  display: inline-block;
}

.home-title-split {
  flex: 1;
  display: inline-block;
  height: 1px;
  background-color: #979797;
}

.home-main-event {
  width: 100%;
  height: auto;
  margin-bottom: 64px;
}

.home-past-event {
  width: 100%;
  height: auto;
  margin-bottom: 84px;
}

.pwa-mobile {
  display: none;
  width: 100%;
  margin-bottom: 15.88px;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    display: block;
  }
}

.pwa-mobile img {
  width: 100%;
  display: block;
}
